/*
------Colors used------
tertiary color: #687864
Font color: #31708E
Link color: #5085A5
Button background: #8FC1E3
Background-color:  #fff  changed from #F7F9FB

------Font-Families------
*Headlines and links*
font-family: 'Proza Libre', sans-serif;
***Content***
font-family: 'Roboto Slab', serif;
 */

:root {
  --primary-font-color: #424242;
  --secondary-font-color: #000; //#3B945E;//#1f4a31;
  //--tertiary-color: #687864;
  //--accent-color: #5271ff; //#f3d250;
  --accent-color: #708AFF;
  --accent-color-2: #F4D666;
  // --accent-color-2: #FF6542;
  //--accent-color-3: #9FB77B;
  --btn-bg-color: #424242;
  --btn-txt-color: #ffffff;
  --btn-bg-hover-color: #99ACFF;
  --bg-color: #ffffff;
  --bg-color-2: #ebebeb;
  --bg-color-3: #c2c2c2;
  --primary-font-family: 'Montserrat', sans-serif;
  --secondary-font-family: '', sans-serif;
  --caption-font-size: 12px;

  --background-image: "/img/tree_tops.jpg";

  //--test-green1: #3B945E;
  //--test-green2: #379683;
  //--test: #3B945E;


  // #5271ff, #ebebd3, #f4d35e, #ee964b, #f95738
  // #5271ff, #06a77d, #d5c67a, #f1a208, #171614
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--bg-color);
  font-family: var(--primary-font-family);
  color: var(--primary-font-color);
  font-weight: 300;
  // margin: 0;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font-family);
  color: var(--secondary-font-color);
  display: block;
}

h1 {
  font-family: var(--primary-font-family);
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

h2 {
  margin-top: 0px;
  font-family: var(--primary-font-family);
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h2::first-letter {
  font-family: var(--primary-font-family);
  font-weight:900;
  font-size: 60px;
  letter-spacing: -0.1em;
  color: var(--accent-color);
}

h3 {
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
}

h4 {
  font-size: 25px;
  font-weight: 400;

}

h5 {
  font-size: 18px;
  font-weight: 500;

}

main {
  margin-top: 100px;
}

/* --------Links------------------------------------------------ */

a {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 400;
}

footer a {
  margin: 0 5px;
}

a:visited,
a:hover,
a:focus,
a:active {
  color: var(--secondary-font-color);
}

h2 a {
  color: var(--secondary-font-color);
}

h2 a:hover {
  color: var(--primary-font-color);
}

/* -----------Buttons---------------------------- */

button,
.button {
  border: none;
  font-family: inherit;
  font-weight: 300;
  opacity: 1;
  // background-color: var(--btn-bg-color);
  background-color: var(--accent-color);
  // color: var(--btn-txt-color);
  color: var(--secondary-font-color);
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 5px;

  /* transition animation */
  transition: 0.5s background-color;
}

button:hover,
button:focus,
.button:hover,
.button:focus {
  cursor: pointer;
  color: var(--secondary-font-color);
  background-color: var(--btn-bg-hover-color);
}

/* ------------Header--------------------------------------- */

#navbar {
  padding: 10px 30px;
  position: fixed;
  top: -10px;
  background-color: var(--bg-color-3);
  transition: top 0.3s;
}

.page-header {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 20px;
  z-index: 10;
}

.page-header__item {
  flex: 0 1 50px;
}

.site-logo{
  font-size: 35px;
  font-weight: 700;
  font-family: var(--primary-font-family);
  color: var(--secondary-font-color);
  line-height: 0.7;
  margin: 0;
  letter-spacing: 0.08em;
}

.site-logo::first-letter {
  font-family: var(--primary-font-family);
  font-weight:900;
  font-size: 60px;
  // letter-spacing: -0.1em;
  color: var(--accent-color);
}

.site-logo:hover{
  color: var(--primary-font-color);
}

img.page-header__item {
  width: 50%;
}

.page-header__item:last-child {
  flex-grow: 1;
  text-align: right;
}

/* ----------------Navigation----------------------------- */
.nav-list {
  list-style-type: none;
  padding: 0;
}

.nav-list li {
  display: inline-block;
}

a.nav-list__item {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 00.05em;
  padding: 0 15px;
  color: var(--secondary-font-color);
}

a.nav-list__item:hover {
  color: var(--primary-font-color);
}

.dropdown-menu__icon {
  position: relative;
  display: none;
}

/* Navigation animation */

/* Brackets go out */

/* Modified from https://codepen.io/EvyatarDa/pen/waKXMd */
li a::before,
li a::after {
  position: absolute;
  opacity: 0;
  color: var(--accent-color);
  font-size: 30px;
  font-weight: 600;
  top: -15px;
  transition: all 0.3s;
}

li a::before {
  content: '{';
  left: -3px;
  transform: translateX(10px);
}

li a::after {
  content: '}';
  right: -3px;
  transform: translateX(-10px);
}

li a:hover::before,
li a:hover::after {
  opacity: 1;
  transform: translateX(0);
}

.nav-list__item--active::before,
.nav-list__item--active::after {
  opacity: 1;
  transform: translateX(0);
}

/* ----------------Footer------------------------------- */
.page-footer {
  padding: 70px 0 110px 0;
  background-color: var(--bg-color-2);
  display: flex;
  justify-content: space-evenly;
}

.social-media__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.contact-section__info {
  width: fit-content;
}

.footer-item {
  width: 320px;
}

.social-icons {
  width: 30px;
  height: 30px;
}

/* --------------General page layout------------------------ */

section {
  padding: 80px 0;
}

.section-content {
  max-width: 80%;
  margin: 0 auto;
}

/* -------------Intro section--------------------------- */

.profile .section-content{
  display: flex;
  flex-flow: row wrap;
  min-height: 325px;
  padding: 35px 10px;
  justify-content: center;
}

.divider-line{
  width: 70%;
  height: 140px;
  background-color: var(--accent-color-2);
  z-index: -1;
  position: absolute;
  left: 15%;
  top: 30%;
}

.profile__portrait {
  max-width: 350px;
  border-radius: 50%;
  background-color: var(--bg-color);
  border: 8px solid var(--bg-color);
}

.profile__item {
  margin: 0 50px;
}

/* --------------About me section--------------------- */
.about-me__background {
  background-image: url("/img/tree_tops.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-me__content {
  background-color: var(--secondary-font-color);
  opacity: 0.92;
  color: var(--bg-color);
  padding: 50px;
  max-width: 60%;
  margin-left: auto;

  h2 {
    margin: 0px;
    color: var(--bg-color);
  }

  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: var(--accent-color);
  }
}

p.about-me__item {
  text-align: justify;
  line-height: 1.7;
}

.resume-download {
  max-width: 100%;
  margin: 0 auto;
  a {
    display: inline-block;
  }
}

/*---------------Skills section------------------------*/
.skills-section {
  .section-content {
    padding-top: 0;
  }
}

/* --------------Projects section--------------------- */

.projects-section {
  background: linear-gradient(var(--bg-color-2) 10%, var(--bg-color) 100%);
}

.project-card {
  display: flex;
  flex-flow: row wrap; 
  gap: 30px;
  justify-content: center;
  padding: 50px 0;
  border-bottom: 3px solid var(--bg-color-3);
}

.project-card__list :last-child {
  border-bottom: none;
}

.project-card__half {
  max-width: 48%;
}

.project-card__screenshot{
  // border: 1px solid var(--primary-font-color);
  border-radius: 5px;
  max-width: 100%;
  max-height: 300px;
  margin: 0;
  padding: 0;
}

.project-card__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  a {
    margin: 10px 5px;
    padding: 10px 15px;
  }
}

.project-card__text-area {
  h5 {
    margin: 0;
  }
}

.project-card__header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.project-card__header {

  h3, h4 {
    margin: 0;
  }

  h4 {
    margin-right: 30px;
  }
}

.tech-tags {
  padding: 0;
}

.tech-tags__item {
  display: inline-block;
  font-family: 'Courier New', Courier, monospace;
  font-size: .75rem;
  margin: 2px 2px;
  padding: 3px 10px;
  border-radius: 5px;
  //border: 1px solid white;
  color: var(--secondary-font-color);
  background-color: var(--accent-color-2);
}

.project-screenshot {
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: 10px auto 0 auto;
}








/*-----------Case Study Page-------------*/

figure {
  max-width: 75%;
  margin: 0 auto;
  img {
    width: 100%;
    // max-width: 600px;
    // max-height: 400px;
  }
  figcaption {
    font-size: var(--caption-font-size);
  }
}

#imbedded_chicken_picker {
  width: 80%;
  height: 500px;
}




/* ------------Media queries---------------------- */
@media all and (max-width: 1024px) {
  h1 {
    font-size: 50px;
  }

  .page-header {
    height: 70px;
  }
  /* ----------dropdown menu---------- */
  .dropdown {
    position: relative;
    display: inline-block;
    font-size: 18px;
  }

  .dropdown-menu__icon {
    display: block;
    margin: 5px 15px;
    position: absolute;
    right: 0;
    top: -10px;
  }

  .dropdown-menu__icon:hover {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    margin: 0;
    right: 10px;
    top: 30px;
    z-index: 1;
    min-width: 300px;
    box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: var(--accent-color-2);
    text-align: center;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown-content li {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid var(--btn-bg-color);
  }

  .about-me__content {
    margin-left: auto;
    max-width: 80%;
  }

  .project-card {
    flex-flow: column wrap;
    align-items: center;

    .project-card__half {
      max-width: 100%;
    }
  }
}
  
@media all and (max-width: 768px) {
  body {
      font-size: 16px;
  }

  .site-logo {
    font-size: 30px;
  }

  .site-logo::first-letter{
    font-size: 55px;
  }

  h1 {
    // font-size: 32px;
    // margin: 10px 0 0 0;
  }
  
  h2 {
    font-size: 30px;
  }

  h2::first-letter {
    font-size: 55px;
  }
  
  .page-header {
    // padding: 10px 20px;
  }
  
  img.page-header__item {
    // width: 75%;
  }

  .page-footer {
    flex-direction: column;
    align-items: center;

    .footer-item {
      width: fit-content
    }
  }

  .profile .section-content {
    flex-flow: column wrap;
    align-items: space-around;

    .profile__item {
      margin: 0 auto;
    }
  }

  .about-me__content {
    max-width: 100%;
  }
}

@media all and (max-width: 500px) {
  body {
    font-size: 14px;
  }

  #navbar {
    padding: 10px 10px;
  }

  .site-logo {
    font-size: 30px;
  }

  .site-logo::first-letter{
    font-size: 50px;
  }

  h1 {
    font-size: 32px;
    margin: 10px 0 0 0;
  }

  h2 {
    font-size: 25px;
  }

  h2::first-letter {
    font-size: 50px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }


  .profile__portrait {
    max-width: 300px;
  }

  .divider-line {
    width: 90%;
    height: 110px;
    left: 5%;
  }

  .about-me__content {
    padding: 25px;
    max-width: 100%;
  }

}

@media all and (max-width: 400px) {
  body {
    font-size: 14px;
  }

  main {
    margin-top: 30px;
  }

  section {
    padding: 40px 0;
  }

  #navbar {
    padding: 15px 10px;
  }

  .site-logo {
    font-size: 25px;
  }

  .site-logo::first-letter{
    font-size: 45px;
  }

  h1 {
    font-size: 28px;
    margin: 10px 0 0 0;
  }

  h2 {
    font-size: 25px;
  }

  h2::first-letter {
    font-size: 45px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }

  .divider-line {
    width: 90%;
    height: 110px;
    left: 5%;
    top: 45%;
  }

  .section-content {
    max-width: 94%;
  }

  .about-me__content {
    padding: 25px;
    max-width: 100%;
  }

}
