/*
------Colors used------
tertiary color: #687864
Font color: #31708E
Link color: #5085A5
Button background: #8FC1E3
Background-color:  #fff  changed from #F7F9FB

------Font-Families------
*Headlines and links*
font-family: 'Proza Libre', sans-serif;
***Content***
font-family: 'Roboto Slab', serif;
 */
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
  color: #424242;
  font-weight: 300;
  line-height: 1.5;
}
h1 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
}
h2 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
}
h3 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
}
h4 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
}
h5 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
}
h6 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}
h2 {
  margin-top: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
h2::first-letter {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 60px;
  letter-spacing: -0.1em;
  color: #708AFF;
}
h3 {
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
}
h4 {
  font-size: 25px;
  font-weight: 400;
}
h5 {
  font-size: 18px;
  font-weight: 500;
}
main {
  margin-top: 100px;
}
/* --------Links------------------------------------------------ */
a {
  color: #708AFF;
  text-decoration: none;
  font-weight: 400;
}
footer a {
  margin: 0 5px;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
}
a:focus {
  color: #000;
}
a:active {
  color: #000;
}
h2 a {
  color: #000;
}
h2 a:hover {
  color: #424242;
}
/* -----------Buttons---------------------------- */
button {
  border: none;
  font-family: inherit;
  font-weight: 300;
  opacity: 1;
  background-color: #708AFF;
  color: #000;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 5px;
  /* transition animation */
  -webkit-transition: 0.5s background-color;
  transition: 0.5s background-color;
}
.button {
  border: none;
  font-family: inherit;
  font-weight: 300;
  opacity: 1;
  background-color: #708AFF;
  color: #000;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 5px;
  /* transition animation */
  -webkit-transition: 0.5s background-color;
  transition: 0.5s background-color;
}
button:hover {
  cursor: pointer;
  color: #000;
  background-color: #99ACFF;
}
button:focus {
  cursor: pointer;
  color: #000;
  background-color: #99ACFF;
}
.button:hover {
  cursor: pointer;
  color: #000;
  background-color: #99ACFF;
}
.button:focus {
  cursor: pointer;
  color: #000;
  background-color: #99ACFF;
}
/* ------------Header--------------------------------------- */
#navbar {
  padding: 10px 30px;
  position: fixed;
  top: -10px;
  background-color: #c2c2c2;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}
.page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 80px;
  padding: 20px;
  z-index: 10;
}
.page-header__item {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 50px;
          flex: 0 1 50px;
}
.site-logo {
  font-size: 35px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #000;
  line-height: 0.7;
  margin: 0;
  letter-spacing: 0.08em;
}
.site-logo::first-letter {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #708AFF;
}
.site-logo:hover {
  color: #424242;
}
img.page-header__item {
  width: 50%;
}
.page-header__item:last-child {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: right;
}
/* ----------------Navigation----------------------------- */
.nav-list {
  list-style-type: none;
  padding: 0;
}
.nav-list li {
  display: inline-block;
}
a.nav-list__item {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 0 15px;
  color: #000;
}
a.nav-list__item:hover {
  color: #424242;
}
.dropdown-menu__icon {
  position: relative;
  display: none;
}
/* Navigation animation */
/* Brackets go out */
/* Modified from https://codepen.io/EvyatarDa/pen/waKXMd */
li a::before {
  position: absolute;
  opacity: 0;
  color: #708AFF;
  font-size: 30px;
  font-weight: 600;
  top: -15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
li a::after {
  position: absolute;
  opacity: 0;
  color: #708AFF;
  font-size: 30px;
  font-weight: 600;
  top: -15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
li a::before {
  content: "{";
  left: -3px;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
li a::after {
  content: "}";
  right: -3px;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
li a:hover::before,
li a:hover::after {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.nav-list__item--active::before,
.nav-list__item--active::after {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
/* ----------------Footer------------------------------- */
.page-footer {
  padding: 70px 0 110px 0;
  background-color: #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.social-media__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.contact-section__info {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footer-item {
  width: 320px;
}
.social-icons {
  width: 30px;
  height: 30px;
}
/* --------------General page layout------------------------ */
section {
  padding: 80px 0;
}
.section-content {
  max-width: 80%;
  margin: 0 auto;
}
/* -------------Intro section--------------------------- */
.profile .section-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  min-height: 325px;
  padding: 35px 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.divider-line {
  width: 70%;
  height: 140px;
  background-color: #F4D666;
  z-index: -1;
  position: absolute;
  left: 15%;
  top: 30%;
}
.profile__portrait {
  max-width: 350px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 8px solid #ffffff;
}
.profile__item {
  margin: 0 50px;
}
/* --------------About me section--------------------- */
.about-me__background {
  background-image: url("tree_tops.8dd3f43e.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-me__content {
  background-color: #000;
  opacity: 0.92;
  color: #ffffff;
  padding: 50px;
  max-width: 60%;
  margin-left: auto;
}
.about-me__content h2 {
  margin: 0px;
  color: #ffffff;
}
.about-me__content a:visited {
  color: #708AFF;
}
.about-me__content a:hover {
  color: #708AFF;
}
.about-me__content a:focus {
  color: #708AFF;
}
.about-me__content a:active {
  color: #708AFF;
}
p.about-me__item {
  text-align: justify;
  line-height: 1.7;
}
.resume-download {
  max-width: 100%;
  margin: 0 auto;
}
.resume-download a {
  display: inline-block;
}
/*---------------Skills section------------------------*/
.skills-section .section-content {
  padding-top: 0;
}
/* --------------Projects section--------------------- */
.projects-section {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #ebebeb), to(#ffffff));
  background: linear-gradient(#ebebeb 10%, #ffffff 100%);
}
.project-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  gap: 30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px 0;
  border-bottom: 3px solid #c2c2c2;
}
.project-card__list :last-child {
  border-bottom: none;
}
.project-card__half {
  max-width: 48%;
}
.project-card__screenshot {
  border-radius: 5px;
  max-width: 100%;
  max-height: 300px;
  margin: 0;
  padding: 0;
}
.project-card__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.project-card__links a {
  margin: 10px 5px;
  padding: 10px 15px;
}
.project-card__text-area h5 {
  margin: 0;
}
.project-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.project-card__header h3, .project-card__header h4 {
  margin: 0;
}
.project-card__header h4 {
  margin-right: 30px;
}
.tech-tags {
  padding: 0;
}
.tech-tags__item {
  display: inline-block;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.75rem;
  margin: 2px 2px;
  padding: 3px 10px;
  border-radius: 5px;
  color: #000;
  background-color: #F4D666;
}
.project-screenshot {
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: 10px auto 0 auto;
}
/*-----------Case Study Page-------------*/
figure {
  max-width: 75%;
  margin: 0 auto;
}
figure img {
  width: 100%;
}
figure figcaption {
  font-size: 12px;
}
#imbedded_chicken_picker {
  width: 80%;
  height: 500px;
}
/* ------------Media queries---------------------- */
@media all and (max-width: 1024px) {
  h1 {
    font-size: 50px;
  }

  .page-header {
    height: 70px;
  }

  /* ----------dropdown menu---------- */
  .dropdown {
    position: relative;
    display: inline-block;
    font-size: 18px;
  }

  .dropdown-menu__icon {
    display: block;
    margin: 5px 15px;
    position: absolute;
    right: 0;
    top: -10px;
  }

  .dropdown-menu__icon:hover {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    margin: 0;
    right: 10px;
    top: 30px;
    z-index: 1;
    min-width: 300px;
    -webkit-box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #F4D666;
    text-align: center;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content li {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #424242;
  }

  .about-me__content {
    margin-left: auto;
    max-width: 80%;
  }

  .project-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .project-card .project-card__half {
    max-width: 100%;
  }
}
@media all and (max-width: 768px) {
  body {
    font-size: 16px;
  }

  .site-logo {
    font-size: 30px;
  }

  .site-logo::first-letter {
    font-size: 55px;
  }

  h2 {
    font-size: 30px;
  }

  h2::first-letter {
    font-size: 55px;
  }

  .page-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .page-footer .footer-item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .profile .section-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-box-align: space-around;
        -ms-flex-align: space-around;
            align-items: space-around;
  }
  .profile .section-content .profile__item {
    margin: 0 auto;
  }

  .about-me__content {
    max-width: 100%;
  }
}
@media all and (max-width: 500px) {
  body {
    font-size: 14px;
  }

  #navbar {
    padding: 10px 10px;
  }

  .site-logo {
    font-size: 30px;
  }

  .site-logo::first-letter {
    font-size: 50px;
  }

  h1 {
    font-size: 32px;
    margin: 10px 0 0 0;
  }

  h2 {
    font-size: 25px;
  }

  h2::first-letter {
    font-size: 50px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }

  .profile__portrait {
    max-width: 300px;
  }

  .divider-line {
    width: 90%;
    height: 110px;
    left: 5%;
  }

  .about-me__content {
    padding: 25px;
    max-width: 100%;
  }
}
@media all and (max-width: 400px) {
  body {
    font-size: 14px;
  }

  main {
    margin-top: 30px;
  }

  section {
    padding: 40px 0;
  }

  #navbar {
    padding: 15px 10px;
  }

  .site-logo {
    font-size: 25px;
  }

  .site-logo::first-letter {
    font-size: 45px;
  }

  h1 {
    font-size: 28px;
    margin: 10px 0 0 0;
  }

  h2 {
    font-size: 25px;
  }

  h2::first-letter {
    font-size: 45px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }

  .divider-line {
    width: 90%;
    height: 110px;
    left: 5%;
    top: 45%;
  }

  .section-content {
    max-width: 94%;
  }

  .about-me__content {
    padding: 25px;
    max-width: 100%;
  }
}
/*# sourceMappingURL=index.bf2dd92d.css.map */
